<template>
  <div class="home">
    <!-- 添加视频容器 -->
    <div class="video-container" :class="{ 'minimized': isMinimized }" v-show="showVideo">
      <!-- 添加关闭按钮 -->
      <div class="video-close-btn" @click="closeVideo">
        <span>×</span>
        <div class="btn-text">进入首页</div>
      </div>
      
      <video
        ref="homeVideo"
        class="full-screen-video"
        :src="videoUrl"
        autoplay
        :muted="isMuted"
        @ended="onVideoEnd"
      ></video>

      <!-- 添加视频控制按钮 -->
      <div class="video-controls">
        <div class="control-group">
          <div class="control-btn" @click="togglePlay">
            <span>{{ isPlaying ? '⏸' : '▶' }}</span>
          </div>
          <div class="btn-text">{{ isPlaying ? '暂停' : '播放' }}</div>
        </div>
        <div class="control-group">
          <div class="control-btn" @click="toggleMute">
            <span>{{ isMuted ? '🔇' : '🔊' }}</span>
          </div>
          <div class="btn-text">{{ isMuted ? '静音' : '有声' }}</div>
        </div>
      </div>
    </div>
    
    <!-- 添加悬浮窗 -->
    <div class="floating-video" v-show="isMinimized" @click="maximizeVideo">
      <video
        ref="floatingVideo"
        class="floating-video-content"
        :src="videoUrl"
        muted
      ></video>
      <div class="floating-play-icon">▶</div>
    </div>
    
    <Dtheader></Dtheader>
    <DTbanner v-show="showBanner"></DTbanner>
    <!-- 路由匹配到的组件将显示在这里 -->
    <div class="view-c">
        <router-view></router-view>
    </div>
    <DTfooter></DTfooter>
  </div>
</template>

<script>
import Dtheader from "@/components/DTheader";
import DTfooter from "@/components/DTfooter";
import DTbanner from "@/components/DTbanner";
export default {
  name: "Home",
  components: {Dtheader,DTfooter,DTbanner},
  data() {
    return {
      showBanner:true,
      showVideo: true,
      videoUrl: "https://chengfakeyong3.eos.fenhu-1.cmecloud.cn/upload/%E5%9F%8E%E5%8F%91%E9%9B%86%E5%9B%A2%E5%AE%A3%E4%BC%A0%E7%89%87.mp4?versionId=null&response-content-disposition=attachment&AWSAccessKeyId=KO3EYMQ5SRDWUNY1K6B4&Expires=1772256925&Signature=Tuj9oYsxpIVL3%2FO1iQxVIjTYbks%3D", // 替换为实际的视频URL
      // videoUrl : "http://www.zsjzkj.com/747a894b69485ee90088bbe8a84f1605.mp4",
      isMuted: true,
      isPlaying: true,
      isMinimized: false,
    };
  },
  watch: {
    '$route'(to, from) {
      let path = to.path;
      if(path == '/newsdetail'){
        this.showBanner = false
      }else{
        this.showBanner = true
      }
    }
  },
  methods: {
    checkRouter(){
      let path = this.$route.path;
      if(path == '/newsdetail'){
        this.showBanner = false
      }
    },
    onVideoEnd() {
      this.showVideo = false;
    },
    closeVideo() {
      // 暂停视频播放
      this.$refs.homeVideo.pause();
      // 切换到最小化状态
      this.isMinimized = true;
      this.isPlaying = false;
      this.isMuted = true;
      this.showVideo = false;
    },
    toggleMute() {
      this.isMuted = !this.isMuted;
    },
    togglePlay() {
      const video = this.$refs.homeVideo;
      if (this.isPlaying) {
        video.pause();
      } else {
        video.play();
      }
      this.isPlaying = !this.isPlaying;
    },
    maximizeVideo() {
      this.isMinimized = false;
      this.$nextTick(() => {
        // 自动播放
        this.$refs.homeVideo.play();
        this.isPlaying = true;
      });
    },
  },
  created() {},
  mounted() {
    this.checkRouter();
    // 视频加载完成后自动播放
    this.$refs.homeVideo.addEventListener('loadedmetadata', () => {
      this.$refs.homeVideo.play();
    });

    // 添加视频播放状态监听
    this.$refs.homeVideo.addEventListener('play', () => {
      this.isPlaying = true;
    });
    this.$refs.homeVideo.addEventListener('pause', () => {
      this.isPlaying = false;
    });
  },
  beforeDestroy() {
    // 清理事件监听
    if (this.$refs.homeVideo) {
      this.$refs.homeVideo.removeEventListener('timeupdate', this.updateProgress);
    }
  },
};
</script>
<style scoped lang="scss">
.home {
  width: 100vw;
  overflow-x: hidden;
}

.video-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 9999;
  background: #000;
  transition: all 0.3s ease;
  overflow: hidden;
  
  &.minimized {
    opacity: 0;
    pointer-events: none;
  }
}

.full-screen-video {
  width: 100vw;
  height: 100vh;
  object-fit: fill;
}

.video-close-btn {
  position: absolute;
  top: 20px;
  right: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  z-index: 10000;

  .btn-text {
    color: #fff;
    font-size: 14px;
    margin-top: 5px;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.8);
  }
  
  span {
    width: 40px;
    height: 40px;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 30px;
    line-height: 1;
    transition: all 0.3s ease;
    
    &:hover {
      background: rgba(0, 0, 0, 0.8);
      transform: scale(1.1);
    }
  }
}

.video-controls {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 30px;
  z-index: 10000;
}

.control-group {
  display: flex;
  flex-direction: column;
  align-items: center;
  
  .btn-text {
    color: #fff;
    font-size: 14px;
    margin-top: 5px;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.8);
  }
}

.control-btn {
  width: 40px;
  height: 40px;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background: rgba(0, 0, 0, 0.8);
    transform: scale(1.1);
  }

  span {
    color: #fff;
    font-size: 20px;
    line-height: 1;
  }
}

.floating-video {
  display: none;
  position: fixed;
  bottom: 20px;
  left: 20px;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  overflow: hidden;
  cursor: pointer;
  z-index: 999;
  background: #000;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  transition: transform 0.3s ease;

  &:hover {
    transform: scale(1.1);
  }

  .floating-video-content {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .floating-play-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
    font-size: 24px;
    background: rgba(0, 0, 0, 0.5);
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
