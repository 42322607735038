<template>
  <div class="top-banner">
    <!--首页轮播-->
    <div class="Choices_banner" id="Choices_banner">
      <el-carousel
        :interval="5000"
        type="card"
        height="360px"
        indicator-position="none"
      >
        <el-carousel-item v-for="(item, i) in carousels" :key="i" >
          <div style="height: 100%; background: white;" >
            <img
              :src="item.picurl"
              @click="goToDetail(item.objecturl)"
              alt=""
              style="width: 100%; height:  330px"
            />
            <!-- <div class="news-title">测试！！！！！！！！！！！！！！！</div> -->
            <div class="news-title">{{ item.memo }}</div>
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
  </div>
</template>

<script>
import {  getAllCaousel } from "../../js/api/index";
export default {
  name: "DTbanner",
  data() {
    return {
      carousels: [],
    };
  },
  methods: {

    //初始化轮播图标
    initcarousel() {
      getAllCaousel().then((res) => {
        this.carousels = res.data;
        var temp = [];
        this.carousels.map(item => {
          var titem =item;
          console.log("item:",item)
          console.log(item.objecturl.split("#")[1])
          titem.objecturl = item.objecturl.split("#")[1]
          temp.push(titem)
        });
        this.carousels = temp
        console.log("carousels:",this.carousels)

      });
    },
    goToDetail(term) {
      console.log(term);
      if (term) {
        // window.open(term);
        this.$router.push({ path: term});
      } else {
        return;
      }
    },
  },
  created() {
    this.initcarousel();
  },
  mounted() {
    
  },
  beforeDestroy() {
  },
};
</script>
<style scoped lang="scss">
.top-banner {
  margin: 0px auto;
  background-color: rgb(255, 254, 254);
  padding: 0px;
  height: 360px;
  width: 1280px;
  overflow: visible;
  position: relative;
  .Choices_banner {
    overflow: hidden;
    position: relative;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }
  .Choices_banner ul {
    width: 600%;
    overflow: hidden;
    list-style: none;
    transition: margin-left 0.6s ease;
    height: 100%;
    display: flex;
  }
  .news-title{
    // padding: 0 15px;
    text-align: center;
    word-break: keep-all;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 15px;
    margin-top: 5px;
    height: 25px;
    color: black;
  }
  
}
</style>
